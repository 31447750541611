import {
    buildUrl,
    deleteEmpty,
    deleteJson,
    getJson,
    postEmpty,
    postJson,
    putJson,
} from "./ajax"

const allResources =
    (name) => (pageNumber, pageSize, sortBy, filter, pattern) => {
        const params = [
            pageNumber ? `pageNumber=${pageNumber}` : null,
            pageSize ? `pageSize=${pageSize}` : null,
            sortBy ? `sortBy=${sortBy}` : null,
            filter ? `filter=${filter}` : null,
            pattern ? `pattern=${pattern}` : null,
        ].filter((i) => i)
        const query = params.length > 0 ? "?" + params.join("&") : ""
        return `/api/${name}${query}`
    }

const searchResources =
    (name) => (pattern, pageNumber, pageSize, sortBy, filter) => {
        const params = [
            pattern ? `pattern=${pattern}` : null,
            sortBy ? `sortBy=${sortBy}` : null,
            filter ? `filter=${filter}` : null,
            pageNumber ? `pageNumber=${pageNumber}` : null,
            pageSize ? `pageSize=${pageSize}` : null,
        ].filter((i) => i)
        const query = params.length > 0 ? "?" + params.join("&") : ""
        return `/api/${name}/search${query}`
    }

const oneResourceById = (name) => (id) => `/api/${name}/${id}`

export const apis = {
    geneticAnalyses: geneticanalyses(),
    reporters: reporters(),
    patients: patients(),
    bacteria: bacteria(),
    pathogens: pathogens(),
    drugs: drugs(),
    hormones: hormones(),
    genes: resource("genes"),
    templates: templates(),
    layouts: layouts(),
    reports: reports(),
    version: version(),
    labresult: labresult(),
    security: security(),
    users: users(),
    referrals: resource("referrals"),
}

export function resource(name) {
    const all = allResources(name)
    const search = searchResources(name)
    const byId = oneResourceById(name)
    return {
        loadAll(pageNumber, pageSize) {
            return getJson(
                all(pageNumber, pageSize),
                `Error getting ${name}`,
                [],
            )
        },
        loadNotAnonymous(pageNumber, pageSize) {
            return getJson(
                all(pageNumber, pageSize, null, "anonymous=false"),
                `Error getting ${name}`,
                [],
            )
        },
        search(pattern, pageNumber, pageSize) {
            return getJson(
                search(pattern, pageNumber, pageSize),
                `Error searching ${name}`,
                [],
            )
        },
        load(id) {
            return getJson(byId(id), `Error getting ${name} by ${id}`, [])
        },
        loadFull(id) {
            return getJson(
                byId(`full/${id}`),
                `Error getting full ${name} by ${id}`,
                [],
            )
        },
        addOne(value) {
            return postJson(all(), value, `Error posting to ${name}`, [])
        },
        updateOne(id, value) {
            return putJson(byId(id), value, `Error putting to ${name}`, [])
        },
        url: {
            all(pageNumber, pageSize, sortBy) {
                return buildUrl(all(pageNumber, pageSize, sortBy))
            },
            search(pattern, pageNumber, pageSize, sortBy) {
                return buildUrl(search(pattern, pageNumber, pageSize, sortBy))
            },
            byId(id) {
                return buildUrl(byId(id))
            },
            relative: {
                all(pageNumber, pageSize, sortBy) {
                    return all(pageNumber, pageSize, sortBy)
                },
                search(pattern, pageNumber, pageSize, sortBy) {
                    return search(pattern, pageNumber, pageSize, sortBy)
                },
                byId(id) {
                    return byId(id)
                },
            },
        },
    }
}

function security() {
    const name = "user"
    return {
        login(email, password) {
            const body = { email, password }
            return postJson(
                `/api/${name}/login`,
                body,
                `Error posting to ${name}`,
                [],
            )
        },
        validate(user) {
            if (user && user.email) {
                const id = encodeURI(user.email)
                return getJson(`/api/users/${id}`, `Error getting ${name}`, [])
            }
            return Promise.resolve({})
        },
        changePassword(email, currentPassword, nextPassword) {
            const name = "users"
            const body = { email, currentPassword, nextPassword }
            return postJson(`/api/${name}/change-password`, body)
        },
    }
}

function users() {
    const resourceName = "users"
    return {
        loadByRole(role) {
            return getJson(
                `/api/${resourceName}?role=${role}`,
                `Error getting ${resourceName}`,
                [],
            )
        },
    }
}

function bacteria() {
    const resourceName = "bacteria"
    return {
        ...resource(resourceName),
        loadAnalysis(id, filter = "") {
            const body = { filter }
            return postJson(
                `/api/${resourceName}/analysis/${id}`,
                body,
                `Error posting to ${resourceName}`,
            ).then((res) => {
                if (res.ajaxError) throw res.ajaxError
                return res
            })
        },
        remove(id) {
            return deleteEmpty(`/api/${resourceName}/${id}`).then((res) => {
                if (res.ajaxError) throw res.ajaxError
                return res
            })
        },
        clone(code, newCode) {
            return postEmpty(
                `/api/${resourceName}/clone/${code}/${newCode}`,
                `Error posting to ${resourceName}`,
                [],
            )
        },
    }
}

function pathogens() {
    const resourceName = "pathogens"
    return {
        ...resource(resourceName),
    }
}

function drugs() {
    const resourceName = "drugs"
    return {
        ...resource(resourceName),
    }
}

function hormones() {
    const resourceName = "hormones"
    return {
        ...resource(resourceName),
    }
}

function reporters() {
    const resourceName = "reporters"
    const allTodos = allResources(`${resourceName}/todos`)
    const allDone = allResources(`${resourceName}/done`)
    return {
        loadTodos(pageNumber, pageSize, sortField, type, pattern) {
            return getJson(
                allTodos(pageNumber, pageSize, sortField, type, pattern),
                `Error getting ${resourceName}`,
                undefined,
                () => {},
            ).then((res) => {
                if (res.ajaxError) throw JSON.parse(res.ajaxError)
                return res
            })
        },
        loadDone(pageNumber, pageSize, sortField, type, pattern) {
            return getJson(
                allDone(pageNumber, pageSize, sortField, type, pattern),
                `Error getting ${resourceName}`,
                undefined,
                () => {},
            ).then((res) => {
                if (res.ajaxError) throw JSON.parse(res.ajaxError)
                return res
            })
        },
        markAsDone(barcode) {
            return postEmpty(
                `/api/${resourceName}/markAsDone/${barcode}`,
                `Error posting to ${resourceName}`,
                undefined,
                () => {},
            ).then((res) => {
                if (res.ajaxError) throw JSON.parse(res.ajaxError)
                return res
            })
        },
    }
}

function geneticanalyses() {
    const name = "geneticanalyses"
    const all = allResources(name)
    const byId = oneResourceById(name)
    return {
        loadOnlineRegistrations(days) {
            return getJson(
                `${all()}/online/${days}`,
                `Error getting ${name}`,
                [],
            )
        },
        loadApprovedPatients(days) {
            return getJson(
                `${all()}/approved/${days}`,
                `Error getting ${name}`,
                [],
            )
        },
        approvePatients(patients) {
            return putJson(`${all()}/approve`, patients)
        },
        removePatientToApprove(id) {
            return deleteEmpty(`${all()}/online/${id}`)
        },
        loadAll(pageNumber, pageSize, sortField, type, pattern) {
            return getJson(
                all(pageNumber, pageSize, sortField, type, pattern),
                `Error getting ${name}`,
                [],
            )
        },
        loadByPatients(patientId) {
            return getJson(
                `${all()}/patients/${patientId}`,
                `Error getting ${name}`,
                [],
            )
        },
        load(barcode) {
            return getJson(byId(barcode), `Error getting ${name}`, [])
        },
        register(value) {
            let { barcode, analyzed, ...body } = value
            return putJson(byId(barcode), body, `Error putting to ${name}`, [])
        },
        updateReportConfiguration(id, value) {
            return putJson(
                `${byId(id)}/report-configuration`,
                value,
                `Error putting to ${name}`,
                [],
            )
        },
        markAsAnalysed(barcode) {
            return postEmpty(
                `${byId(barcode)}/analysed`,
                `Error posting to ${name}`,
                [],
            )
        },
        updateBarcode(oldBarcode, newBarcode) {
            return postEmpty(
                `${byId(oldBarcode)}/update/${newBarcode}`,
                `Error posting to ${name}`,
                undefined,
                () => {},
            ).then((res) => {
                if (res.ajaxError) throw JSON.parse(res.ajaxError)
                return res
            })
        },
        removeBarcode(barcode, force) {
            return deleteJson(`${byId(barcode)}/${!!force}`).then((res) => {
                if (res.ajaxError) throw res.ajaxError
                return res
            })
        },
        fixBarcode(oldBarcode, newBarcode) {
            return postEmpty(
                `${byId(oldBarcode)}/fix/${newBarcode}`,
                `Error posting to ${name}`,
                undefined,
                () => {},
            ).then((res) => {
                if (res.ajaxError) throw JSON.parse(res.ajaxError)
                return res
            })
        },
        assignReporter(barcodes, reporter) {
            const body = { barcodes }
            return postJson(
                `/api/${name}/assign-reporter/${reporter}`,
                body,
            ).then((res) => {
                if (res.ajaxError) throw JSON.parse(res.ajaxError)
                return res
            })
        },
        unassignReporter(barcodes) {
            const body = { barcodes }
            return postJson(`/api/${name}/unassign-reporter`, body).then(
                (res) => {
                    if (res.ajaxError) throw JSON.parse(res.ajaxError)
                    return res
                },
            )
        },
        createPDF(barcode, layout) {
            return postEmpty(
                `${byId("pdf/" + barcode)}/${layout}`,
                `Error posting to ${name}`,
            ).then((res) => {
                if (res.ajaxError) throw JSON.parse(res.ajaxError)
                return res
            })
        },
        createAllPDF(barcode) {
            return postEmpty(
                `${byId("allpdf/" + barcode)}`,
                `Error posting to ${name}`,
            ).then((res) => {
                if (res.ajaxError) throw JSON.parse(res.ajaxError)
                return res
            })
        },
        reimport(barcode) {
            return postEmpty(
                `${byId(barcode)}/reimport`,
                `Error posting to ${name}`,
            ).then((res) => {
                if (res.ajaxError) throw JSON.parse(res.ajaxError)
                return res
            })
        },
        url: {
            exportMacrobiota() {
                return buildUrl(`${all()}/export/macrobiota`)
            },
            downloadPDF(barcode, layout, reportMode) {
                return buildUrl(
                    `${byId(barcode)}/${layout}/${reportMode}/download`,
                )
            },
        },
        sendApprovedEmail(barcode, options) {
            const body = { ...options }
            return postJson(`/api/${name}/send-approved/${barcode}`, body).then(
                (res) => {
                    if (res.ajaxError) throw JSON.parse(res.ajaxError)
                    return res
                },
            )
        },
    }
}

function labresult() {
    const name = "files"
    return {
        import(id, barcodes, analysisType) {
            return putJson(
                `/api/${name}/${analysisType}/${id}`,
                barcodes,
                `Error putting to ${name}/${id}`,
                { error: true },
            )
        },
    }
}

function layouts() {
    const resourceName = "layouts"
    const contentById = (id) => `/api/${resourceName}/content/${id}`

    return {
        ...resource(resourceName),
        setDefault(id) {
            return postEmpty(
                `/api/${resourceName}/setDefault/${id}`,
                `Error posting to ${resourceName}`,
                [],
            )
        },
        content: {
            url: {
                byId(id) {
                    return buildUrl(contentById(id))
                },
            },
        },
    }
}

function templates() {
    const resourceName = "templates"
    return {
        ...resource(resourceName),
        loadAllWithAllSections(pageNumber, pageSize) {
            return getJson(
                allResources(`${resourceName}/allSections`)(
                    pageNumber,
                    pageSize,
                ),
                `Error getting ${resourceName}`,
                [],
            )
        },
        clone(id, name) {
            const body = { name }
            return postJson(
                `/api/${resourceName}/clone/${id}`,
                body,
                `Error posting to ${resourceName}`,
                [],
            )
        },
        setDefault(id) {
            return postEmpty(
                `/api/${resourceName}/setDefault/${id}`,
                `Error posting to ${resourceName}`,
                [],
            )
        },
        remove(id) {
            return deleteEmpty(`/api/${resourceName}/${id}`).then((res) => {
                if (res.ajaxError) throw res.ajaxError
                return res
            })
        },
    }
}

function reports() {
    const name = "reports"
    const byId = oneResourceById(name)
    return {
        load(barcode) {
            return getJson(byId(barcode), `Error getting ${name}`).then(
                (res) => {
                    if (res.ajaxError) throw JSON.parse(res.ajaxError)
                    return res
                },
            )
        },
        saveSectionFreetext(value) {
            let { barcode, ...data } = value
            const body = { sectionFreetext: data }
            return putJson(
                `/api/${name}/${barcode}/sections/freetext`,
                body,
                `Error putting to ${name} (freetext)`,
                [],
            )
        },
        resetSectionFreetext(barcode, templateId, sectionId, language) {
            return getJson(
                `/api/${name}/${barcode}/section/${templateId}/${sectionId}/reset/${language}`,
                `Error getting ${name}`,
                undefined,
                (error) => {
                    throw error
                },
            ).then((res) => {
                if (res.ajaxError) throw JSON.parse(res.ajaxError)
                return res
            })
        },
        saveSectionImage(value) {
            let { barcode, ...data } = value
            const body = { sectionImage: data }
            return putJson(
                `/api/${name}/${barcode}/sections/image`,
                body,
                `Error putting to ${name} (image)`,
                [],
            )
        },
        saveSectionRatio(value) {
            let { barcode, ...data } = value
            const body = { sectionRatio: data }
            return putJson(
                `/api/${name}/${barcode}/sections/ratio`,
                body,
                `Error putting to ${name} (ratio)`,
                [],
            )
        },
        resetSectionRatio(value) {
            let { barcode, ...data } = value
            const body = { sectionRatio: data }
            return putJson(
                `/api/${name}/${barcode}/resetsections/ratio`,
                body,
                `Error putting to ${name} (ratio)`,
                [],
            )
        },
        url: {
            byId(id) {
                return buildUrl(byId(id))
            },
        },
        sendEmail(
            barcode,
            layout,
            to,
            patientAttachmentRule,
            referralAttachmentRule,
        ) {
            const body = {}
            return postJson(
                `/api/${name}/send/${barcode}/${layout}/${to}/${patientAttachmentRule}/${referralAttachmentRule}`,
                body,
            ).then((res) => {
                if (res.ajaxError) throw JSON.parse(res.ajaxError)
                return res
            })
        },
    }
}

function patients() {
    const name = "patients"
    const byId = oneResourceById(name)
    const all = allResources(name)
    const subresource = (subname) => ({
        updateOne(id, barcode, value) {
            return putJson(
                `${byId(id)}/${subname}/${barcode}`,
                value,
                `Error putting to ${name / subname}`,
                [],
            )
        },
        loadLast(id) {
            return getJson(
                `${byId(id)}/${subname}/last`,
                `Error getting ${subname} by ${id}`,
                null,
                () => {}, // no error message if we still didn't register the details
            )
        },
    })
    return {
        ...resource(name),
        loadFull(id, barcode) {
            return getJson(
                `${byId(id)}/full/${barcode}`,
                `Error getting ${name} by ${id}`,
                [],
            )
        },
        loadBarcode(barcode) {
            return getJson(
                `${byId(
                    "00000000-0000-0000-0000-000000000000",
                )}/full/${barcode}`,
                `Error getting ${name} by ${barcode}`,
                [],
            )
        },
        updateFull(id, value) {
            return putJson(
                `${byId(id)}/full`,
                value,
                `Error putting to ${name}`,
                [],
            )
        },
        addFull(value) {
            return postJson(
                `${all()}/full`,
                value,
                `Error posting to ${name}`,
                [],
            )
        },
        remove(id) {
            return deleteEmpty(`${byId(id)}`).then((res) => {
                if (res.ajaxError) throw res.ajaxError
                return res
            })
        },
        physicalDescription: subresource("physical-description"),
        guardian: subresource("guardian"),
        diseases: subresource("diseases"),
        drugs: subresource("drugs"),
    }
}

function version() {
    const name = "version"
    return {
        get() {
            return getJson(`/api/${name}`, `Error getting ${name}`, "")
        },
    }
}
