//import Echo from "../views/shared/components/echo"
import React from "react"
import { Route, Router } from "react-router"
import { createMemoryHistory } from "history"

import Dashboard from "../views/dashboard"
import ReporterDashboard from "../views/reporter"

import AddGeneticAnalysis from "../views/geneticanalyses/add"
import ShowGeneticAnalysis from "../views/geneticanalyses/show"
import EditGeneticAnalysis from "../views/geneticanalyses/edit"
import EditRegistrationEmail from "../views/geneticanalyses/edit-emails"
import SummaryGeneticAnalysis from "../views/geneticanalyses/summary"
import RegisterPatient from "../views/geneticanalyses/register-patient"
import RegisterPatientPhysicalDescription from "../views/geneticanalyses/register-patient-physical"
import RegisterPatientGuardian from "../views/geneticanalyses/register-patient-guardian"
import RegisterPatientDiseases from "../views/geneticanalyses/register-patient-diseases"
import RegisterPatientDrugs from "../views/geneticanalyses/register-patient-drugs"
import RegisterEmails from "../views/geneticanalyses/register-emails"
import RegisterSummary from "../views/geneticanalyses/register-summary"

import ApprovePatient from "../views/geneticanalyses/approve-patient"

import ImportLabResult from "../views/labresult/import"

import Bacteria from "../views/bacteria"
import BacteriaList from "../views/bacteria/list"
import AddBacteria from "../views/bacteria/add"
import EditBacteria from "../views/bacteria/edit"

import Hormones from "../views/hormones"
import HormonesList from "../views/hormones/list"
import AddHormon from "../views/hormones/add"
import EditHormones from "../views/hormones/edit"

import Genes from "../views/genes"
import GenesList from "../views/genes/list"
import AddGene from "../views/genes/add"
import EditGene from "../views/genes/edit"

import Templates from "../views/templates"
import TemplatesList from "../views/templates/list"
import AddTemplate from "../views/templates/add"
import EditTemplate from "../views/templates/edit"

import Patients from "../views/patients"
import PatientsList from "../views/patients/list"
import EditPatient from "../views/patients/edit"
import EditPatientBarcode from "../views/patients/editbarcode"
import Import from "../views/import"

import Referrals from "../views/referrals"
import ReferralsList from "../views/referrals/list"
import EditReferral from "../views/referrals/edit"
import AddReferral from "../views/referrals/add"

import Report from "../views/reports"
import Layout from "../views/layouts"
import EditLayout from "../views/layouts/edit"

import { compose, identity, join, keys, map, sortBy, toPairs } from "ramda"

export const routes = {
    dashboard: {
        index: { url: "/", component: Dashboard },
    },
    reporter: {
        index: { url: "/reporter", component: ReporterDashboard },
    },
    import: {
        index: { url: "/import", component: Import },
    },
    approve: {
        patients: {
            url: "/approve/patients",
            component: ApprovePatient,
        },
    },
    geneticAnalyses: {
        add: {
            url: "/geneticanalyses/add",
            component: AddGeneticAnalysis,
        },
        addback: {
            url: "/geneticanalyses/add/:id",
            component: AddGeneticAnalysis,
            buildUrl(id) {
                return `/geneticanalyses/add/${id}`
            },
        },
        register: {
            url: "/geneticanalyses/add/:id/:mode/:analysisType",
            component: AddGeneticAnalysis,
            buildUrl(id, analysisType) {
                return `/geneticanalyses/add/${id}/edit/${analysisType}`
            },
        },
        registerPatient: {
            url: "/geneticanalyses/register-patient/:id",
            component: RegisterPatient,
            buildUrl(id) {
                return `/geneticanalyses/register-patient/${id}`
            },
        },
        registerPatientPhysicalDescription: {
            url: "/geneticanalyses/register-patient-physical/:id",
            component: RegisterPatientPhysicalDescription,
            buildUrl(id) {
                return `/geneticanalyses/register-patient-physical/${id}`
            },
        },
        registerPatientGuardian: {
            url: "/geneticanalyses/register-patient-guardian/:id",
            component: RegisterPatientGuardian,
            buildUrl(id) {
                return `/geneticanalyses/register-patient-guardian/${id}`
            },
        },
        registerPatientDiseases: {
            url: "/geneticanalyses/register-patient-diseases/:id",
            component: RegisterPatientDiseases,
            buildUrl(id) {
                return `/geneticanalyses/register-patient-diseases/${id}`
            },
        },
        registerPatientDrugs: {
            url: "/geneticanalyses/register-patient-drugs/:id",
            component: RegisterPatientDrugs,
            buildUrl(id) {
                return `/geneticanalyses/register-patient-drugs/${id}`
            },
        },
        registerEmails: {
            url: "/geneticanalyses/register-emails/:id",
            component: RegisterEmails,
            buildUrl(id) {
                return `/geneticanalyses/register-emails/${id}`
            },
        },
        registerSummary: {
            url: "/geneticanalyses/register-summary/:id",
            component: RegisterSummary,
            buildUrl(id) {
                return `/geneticanalyses/register-summary/${id}`
            },
        },
        show: {
            url: "/geneticanalyses/show/:id",
            component: ShowGeneticAnalysis,
            buildUrl(id) {
                return `/geneticanalyses/show/${id}`
            },
        },
        edit: {
            url: "/geneticanalyses/edit/:id",
            component: EditGeneticAnalysis,
            buildUrl(id) {
                return `/geneticanalyses/edit/${id}`
            },
        },
        summary: {
            url: "/geneticanalyses/summary/:id",
            component: SummaryGeneticAnalysis,
            buildUrl(id) {
                return `/geneticanalyses/summary/${id}`
            },
        },
        registrationEmail: {
            url: "/geneticanalyses/registration-email/:id",
            component: EditRegistrationEmail,
            buildUrl(id) {
                return `/geneticanalyses/registration-email/${id}`
            },
        },
    },
    labresult: {
        import: { url: "/laberesult", component: ImportLabResult },
    },
    hormones: {
        index: {
            url: "/hormones",
            component: Hormones,
            routes: {
                list: { url: "/hormones", component: HormonesList },
                add: {
                    url: "/hormones/add",
                    component: AddHormon,
                },
                edit: {
                    url: "/hormones/edit/:id",
                    component: EditHormones,
                    buildUrl(id) {
                        return `/hormones/edit/${id}`
                    },
                },
            },
        },
    },
    bacteria: {
        index: {
            url: "/bacteria",
            component: Bacteria,
            routes: {
                list: { url: "/bacteria", component: BacteriaList },
                add: {
                    url: "/bacteria/add",
                    component: AddBacteria,
                },
                addwithdata: {
                    url: "/bacteria/add/:level/:group",
                    component: AddBacteria,
                    buildUrl(level, group) {
                        return `/bacteria/add/${level}/${group}`
                    },
                },
                edit: {
                    url: "/bacteria/edit/:id",
                    component: EditBacteria,
                    buildUrl(id) {
                        return `/bacteria/edit/${id}`
                    },
                },
            },
        },
    },
    genes: {
        index: {
            url: "/genes",
            component: Genes,
            routes: {
                list: { url: "/genes", component: GenesList },
                add: { url: "/genes/add", component: AddGene },
                edit: {
                    url: "/genes/edit/:id",
                    component: EditGene,
                    buildUrl(id) {
                        return `/genes/edit/${id}`
                    },
                },
            },
        },
    },
    templates: {
        index: {
            url: "/templates",
            component: Templates,
            routes: {
                list: { url: "/templates", component: TemplatesList },
                add: { url: "/templates/add", component: AddTemplate },
                edit: {
                    url: "/templates/edit/:id",
                    component: EditTemplate,
                    buildUrl(id) {
                        return `/templates/edit/${id}`
                    },
                },
            },
        },
    },
    reports: {
        show: {
            url: "/reports/:id",
            component: Report,
            buildUrl(id) {
                return `/reports/${id}`
            },
        },
        toPrintUrl: function (item) {
            const { layout, barcode, reportMode, token } = item
            return `/report.html?report=${reportMode}&layout=${encodeURIComponent(
                layout,
            )}&barcode=${encodeURIComponent(
                barcode,
            )}&token=${encodeURIComponent(token)}`
        },
    },
    layouts: {
        index: { url: "/layouts", component: Layout },
        edit: {
            url: "/layouts/edit/:id",
            component: EditLayout,
            buildUrl(id) {
                return `/layouts/edit/${id}`
            },
        },
    },
    patients: {
        edit: {
            url: "/patients/editbarcode/:id",
            component: EditPatientBarcode,
            buildUrl(id) {
                return `/patients/editbarcode/${id}`
            },
        },
        index: {
            url: "/patients",
            component: Patients,
            routes: {
                list: { url: "/patients", component: PatientsList },
                edit: {
                    url: "/patients/edit/:id",
                    component: EditPatient,
                    buildUrl(id) {
                        return `/patients/edit/${id}`
                    },
                },
            },
        },
    },
    referrals: {
        index: {
            url: "/referrals",
            component: Referrals,
            routes: {
                list: { url: "/referrals", component: ReferralsList },
                add: {
                    url: "/referrals/add",
                    component: AddReferral,
                },
                edit: {
                    url: "/referrals/edit/:id",
                    component: EditReferral,
                    buildUrl(id) {
                        return `/referrals/edit/${id}`
                    },
                },
            },
        },
    },
}

export const menuItems = [
    {
        title: "Main",
        items: [
            { title: "Home", url: routes.dashboard.index.url, isDefault: true },
            { title: "Reporter", url: routes.reporter.index.url },
            { title: "Import", url: routes.import.index.url },
            {
                title: "Approve",
                url: routes.approve.patients.url,
            },
        ].filter((i) => i),
    },
    {
        title: "Registries",
        items: [
            { title: "Bacteria", url: routes.bacteria.index.url },
            { title: "Genes", url: routes.genes.index.url },
            { title: "Hormones", url: routes.hormones.index.url },
            { title: "Patients", url: routes.patients.index.url },
            { title: "Referrals", url: routes.referrals.index.url },
        ],
    },
    {
        title: "Report",
        items: [
            { title: "Templates", url: routes.templates.index.url },
            { title: "Layouts", url: routes.layouts.index.url },
        ],
    },
]

const buildPath = compose(
    join("/"),
    map((p) => ":" + p),
    sortBy(identity),
    keys,
)

const buildUrl = compose(
    join("/"),
    map((pair) => pair[1]),
    sortBy((pair) => pair[0]),
    toPairs,
)

export function testWithRouter(
    component,
    { path = "path", params = {}, withHistory } = {},
) {
    const routePath = path + "/" + buildPath(params)
    const memoryHistory = createHistory(params, path)
    const router = (
        <Router history={memoryHistory}>
            <Route path={routePath} render={component} />
        </Router>
    )
    return withHistory ? [router, memoryHistory] : router
}

export function createHistory(params = {}, path = "path") {
    const url = path + "/" + buildUrl(params)
    return createMemoryHistory({
        initialEntries: [url],
        initialIndex: 0,
    })
}

export function testWithHistory(Component, params = {}, path = "path") {
    const history = createHistory(params, path)
    return <Component match={{ params }} history={history} />
}
