import React from "react"
import { DropdownItem } from "@atlaskit/dropdown-menu"
import { Link } from "react-router-dom"
import { routes } from "../../shared/routing"
import {
    barcodeCanBeUpdated,
    canBeFinalized,
    canBeRegistered,
    isAssignable,
    isComplete,
    isDone,
} from "../geneticanalyses/schemas"

export const notRegisteredMenuItem = (item) => {
    const url = routes.geneticAnalyses.register.buildUrl(
        item.barcode,
        item.analysisType,
    )
    if (canBeRegistered(item))
        return (
            <DropdownItem>
                <Link to={url}>Register</Link>
            </DropdownItem>
        )
}

export const reimportMenuItem = (item, handler) => {
    if (isComplete(item))
        return (
            <DropdownItem>
                <Link to="" onClick={handler(item)}>
                    Reimport
                </Link>
            </DropdownItem>
        )
}

export const removeBarcodeMenuItem = (item, handler) => {
    return (
        <DropdownItem>
            <Link to="" onClick={handler(item)}>
                Remove Barcode
            </Link>
        </DropdownItem>
    )
}

export const markAsDoneMenuItem = (item, handler) => {
    if (canBeFinalized(item)) {
        return (
            <DropdownItem>
                <Link to="" onClick={handler(item)}>
                    Mark as Done
                </Link>
            </DropdownItem>
        )
    }
}

export const recreatePDFMenuItem = (item, handler) => {
    if (isComplete(item))
        return (
            <DropdownItem>
                <Link to="" onClick={handler(item)}>
                    Create all PDFs
                </Link>
            </DropdownItem>
        )
}

export const updateBarcodeMenuItem = (item, handler) => {
    if (barcodeCanBeUpdated(item))
        return (
            <DropdownItem>
                <Link to="" onClick={handler(item)}>
                    Change Barcode
                </Link>
            </DropdownItem>
        )
}

export const assignReporterMenuItem = (item, handler) => {
    if (isAssignable(item))
        return (
            <DropdownItem>
                <Link to="" onClick={handler(item)}>
                    Assign Reporter
                </Link>
            </DropdownItem>
        )
}
